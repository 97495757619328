<template>
  <footer class="xm-footer">
    <!-- ie svg 来处理高斯模糊 -->
    <!-- <div class="blur-content">
      <svg version="1.1" 
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:ev="http://www.w3.org/2001/xml-events"     
        baseProfile="full">
        <defs>
            <filter id="blur">
                <feGaussianBlur stdDeviation="10" />
            </filter>
        </defs>
        <image xlink:href="mm1.jpg" x="0" y="0" height="191" width="265" filter="url(#blur)" />
      </svg>
    </div> -->
    <div class="xm-footer-top">
      <!-- <img class="xm-footer-top-logo" src="//img.ibestfanli.com/xmilesWebsite2/img_logo_svg.svg" alt=""> -->
      <img class="xm-footer-top-logo" :src="imgUrl" alt="">
      <!-- <span class="xm-footer-top-phone-font ifPc">商务洽谈：bd@xmiles.cn</span>
      <span class="xm-footer-top-phone-font ifPc">合作推广：market@xmiles.cn</span>
      <span class="xm-footer-top-phone-font ifPc">加入我们：hr@xmiles.cn</span> -->
      <div class="xm-footer-top-information ifPhone">
        <div class="xm-footer-top-list xm-footer-top-about">
          <span class="xm-footer-top-list-title">联系我们</span>
          <span class="xm-footer-top-list-span">商务洽谈：bd@xmiles.cn</span>
          <span class="xm-footer-top-list-span">合作推广：market@xmiles.cn</span>
          <span class="xm-footer-top-list-span">加入我们：hr@xmiles.cn</span>
          <span class="xm-footer-top-list-span">广州总部：广州市天河区华夏路32号太平洋金融大厦30、31全层</span>
          <span class="xm-footer-top-list-span">成都分公司：四川省成都市高新区吉瑞三路99号皇庭国际中心A座1905</span>
          <span class="xm-footer-top-list-span">北京分公司：北京市朝阳区奥运村街道北京文化创意大厦C3</span>
          <!-- <span class="xm-footer-top-list-span">北京分公司：北京市朝阳区中国铁建广场B座20层</span> -->
        </div>
        <div class="xm-footer-top-list">
            <span class="xm-footer-top-list-title">关于我们</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('公司简介')">公司简介</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('企业历程')">企业历程</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('管理团队')">管理团队</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('企业文化')">企业文化</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('联系我们')">联系我们</span>
        </div>
        <div class="xm-footer-top-list xm-footer-top-develope">
            <span class="xm-footer-top-list-title">员工发展</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('人才培育')">人才培育</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('工作环境')">工作环境</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('员工活动')">员工活动</span>
        </div>
        <div class="xm-footer-top-list xm-footer-top-develope">
            <span class="xm-footer-top-list-title">加入我们</span>
            <!-- <span class="xm-footer-top-list-span"  @click="handleSpanLocation('招聘岗位')">招聘岗位</span> -->
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('社会招聘')">社会招聘</span>
            <span class="xm-footer-top-list-span"  @click="handleSpanLocation('校园招聘')">校园招聘</span>
        </div>
      </div>
    </div>
    <div class="xm-footer-bottom">
      <div class="xm-footer-bottom-font">
        <span class="xm-footer-span big"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15016492号</a>  |  ICP经营许可证：粤B2-20200925  |  广州小迈网络科技有限公司 版权所有  | Copyright © 2024 XMiles. All Rights Reserved.</span>
        <span class="xm-footer-span small"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15016492号</a>  |  ICP经营许可证：粤B2-20200925  | <br>  广州小迈网络科技有限公司 版权所有  | Copyright © 2024 XMiles. All Rights Reserved.</span>
      </div>
    </div>
  </footer>
</template>

<script>
import Config from 'utils/Config';
export default {
    name: 'footerPc',
    data(){
        return {
          imgUrl:require("../../xmHeader/assets/logo_xmiles_foot.svg")
        }
    },
    methods:{
      handleSpanLocation: function (name) {
      let urlArray = {
        "公司简介": "/about.html#aboutIntro",
        "企业历程": "/about.html#aboutCourse",
        "管理团队": "/about.html#aboutManage",
        "企业文化": "/about.html#aboutCulture",
        "联系我们": "/about.html#aboutContact",
        "人才培育": "/develop.html#developFoster",
        "工作环境": "/develop.html#developEnv",
        "员工活动": "/develop.html#developActivity",
        // "招聘岗位": "/hire.html#hireFuture"
        // "社会招聘": "https://app.mokahr.com/social-recruitment/xmiles/39484",
        // "校园招聘": "https://app.mokahr.com/campus-recruitment/xmiles/37612"
      };
      // window.location.href = window.location.origin + urlArray[name];
      if (name === "社会招聘") {
        window.open("https://app.mokahr.com/social-recruitment/xmiles/39484");
      } else if (name === "校园招聘") {
        window.open("https://app.mokahr.com/campus-recruitment/xmiles/37612");
      } else {
        window.location.href = (Config.domain + urlArray[name]);
      }
    },
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
.xm-footer{
  width: 100%;
  overflow: hidden;
  // height: 330px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 0px 0px rgba(225,225,225,1);
  border-radius: 2px;
  position: relative;
  z-index: 100;
  border-top: 1px solid #E1E1E1;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.3);
  }

  .blur-content{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }

}
    
.xm-footer-top{
  width: 1200px;
  // padding: 0 120px;
  margin: 0 auto;
  // width: 100%;
  // height: 285px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  // padding: 0 85px;
  position: relative;
  z-index: 1;
}
  
.xm-footer-top-logo{
    width: 224px;
    height: 47px;
    margin-top: 60px;
}

.xm-footer-top-information{
    // height: 1px;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
}
    
.xm-footer-top-list{
    display: flex;
    flex-direction: column;
}
    
.xm-footer-top-list-title{
    display: block;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #222222;
    margin-bottom: 24px;
}

.xm-footer-top-list-span{
    display: block;
    font-size: 14px;
    margin-bottom: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    height: 24px;
    line-height: 24px;

    &:last-child{
      margin-bottom: 0;
    }
}

.xm-footer-top-list-span:hover {
    cursor: pointer;
    color: #3292FF;
}

.xm-footer-top-about{
    margin-left: 86px;
    margin-right: 75px;
}

.xm-footer-top-develope{
    margin-left: 75px;
}
    
.xm-footer-bottom{
    width: 100%;
    // height: 45px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FBFBFB;
    position: relative;
    z-index: 1;
    padding: 20px 0;
}
    
.xm-footer-bottom-font{
    // height: 15px;
    // line-height: 15px;
}

.xm-footer-span{
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    opacity: .6;
    a{
      color: #222222;
      &:hover{
        color: #3292FF;
      }
    }
}
.xm-footer-bottom:after{
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    background-color: rgba(255,255,255,0.3);
}
.small{
  display: none;
}


// 适配其他尺寸
@media screen and  (max-width: 1200px) {
  .big{
    display: none;
  }
  .small{
    text-align: center;
    display: block;
    font-size: 11px;
    line-height: 17px;
  }

  .xm-footer-bottom{
    padding: 23px 0;
  }
  .xm-footer-top{
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 95px 0 70px;
    // width: 815px;
    width: 100%;
    box-sizing: border-box;
    padding: 80px 30px;
  }
  .xm-footer-top-about{
    margin: 0;
  }
  .xm-footer-top-information{
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .xm-footer-top-logo{
    display: none;
  }

  .xm-footer-top-develope{
    margin-left: 0;
  }
  .xm-footer-top-list-title{
    font-size: 18px;
    margin-bottom: 24px;
  }
  .xm-footer-top-list-span{
    font-size: 14px;;
  }
}

// @media screen and  (max-width: 900px) {
//   .xm-footer-top{
//     width: 648px;
//   }
// }
</style>